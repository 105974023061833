
















































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import Intro from '@/views/home/intro/Intro.vue'
import { teamBenefit } from '@/api/data'
import { ITeamBenefit } from '../../api/types'

@Component({
    name: 'Careers',
    components: {
      Intro
    }
})
export default class extends Vue {
  private teamBenefitsData: Array<ITeamBenefit> = []
  
  async mounted() {
    const data = await teamBenefit() as any
    this.teamBenefitsData = data
  }
}
